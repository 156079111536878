.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
}

.container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.rules {
  background: #fff;
  padding: 5px;
  border:3px solid #46C7C7;
  position: absolute;
  text-decoration: none;
  font-size: 24px;
  top: 10px;
  right: 10px;
  color: black;
  
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

ol, ul {
  padding-left: 30px;
}

h1 {
  margin-top: 30px;
  margin-bottom: 10px;
}

.board-row:after {
  clear: both;
  content: "";
  display: table;
}

.game-board {
  display: inline-block;
  margin: 20px auto;
}

.newgame {
  -webkit-appearance: button;
    -webkit-writing-mode: horizontal-tb !important;
    text-rendering: auto;
    color: buttontext;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: center;
    align-items: flex-start;
    cursor: default;
    background-color: buttonface;
    box-sizing: border-box;
    margin: 0em;
    font: 400 13.3333px Arial;
    padding: 1px 6px;
    border-width: 2px;
    border-style: outset;
    border-color: buttonface;
    border-image: initial;
}

.rules{
  width: 100px,;
  background: #fff;
  font-size: 16px;
  text-align: center;
  color: black;
  text-decoration: none;
  border: solid;
  border-color: #46C7C7;
  border-width: 3px;
  padding: 5px;
  margin: 0px 0px 50px 0px;
}

.game {
  display: flex;
  flex-direction: column;
  width: 100%; 
  text-align: center;
  display: inline-block;
}

.error{
  margin: 10px;
}

.game-row {
  display: flex;
  flex-direction: row;
  background-color: #ff000;
}

.square:focus {
  outline: none;
}

.inputForm {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0px 20px 15px 20px;
}

.inputBox {
  min-width: 100px;
  max-width: 250px;
  width: 30%;
  margin: 0px 7px;
  padding: 5px;
}

.startBtn {
  border: solid;
  border-color: #46C7C7;
  border-width: 3px;
  padding: 5px;
  margin: 0px 25px;
  color: black;
  text-decoration: none;
  background-color: #fff;
}

.kbd-navigation .square:focus {
  background: #ddd;
}
